<template>
  <div class="text">
    <div class="text-content clearfix">
      <div class="tip" v-if="content != ''">
        考试倒计时：{{ limitTime }} 总分{{ content.exam_info.score }}分
        {{ content.exam_info.pass }}分及格
      </div>
      <div class="left">
        <div class="left-title">答题卡</div>
        <ul
          class="answer-sheet"
          v-for="item in content.questions"
          :key="item.type_name"
        >
          <li class="category" v-if="item.question_list.length > 0">
            <p class="category-title">
              {{ item.type_name }}（共{{ item.number }}题，合计{{
                item.total_score
              }}分）
            </p>
            <span
              class="test-num-box"
              v-for="(num, index) in item.question_list"
              :key="num.id"
            >
              <span
                class="test-num"
                :class="
                  selectedDta[`question_${item.type}_${num.id}`] != ''
                    ? 'active'
                    : ''
                "
                >{{ index + 1 }}</span
              >
            </span>
          </li>
        </ul>
      </div>
      <div class="right">
        <ul v-for="item in content.questions" :key="item.type">
          <li v-if="item.question_list.length > 0">
            <div class="title">
              {{ item.type_name }}（共{{ item.number }}题，合计{{
                item.total_score
              }}分）
            </div>
            <ul class="category" v-if="item.type != 4">
              <li
                v-for="(timu, timuIndex) in item.question_list"
                :key="timu.id"
              >
                <div class="question-title">
                  {{ timuIndex + 1 }}、{{ timu.question }}
                </div>
                <label
                  class="question-data"
                  v-for="data in timu.selectdata"
                  :key="data.key"
                >
                  <input
                    v-if="item.type != 2"
                    type="radio"
                    :name="'timu_' + timu.id"
                    :value="data.key"
                    v-model="selectedDta[`question_${item.type}_${timu.id}`]"
                  />
                  <input
                    v-else
                    type="checkbox"
                    :name="'timu_' + timu.id"
                    :value="data.key"
                    v-model="selectedDta[`question_${item.type}_${timu.id}`]"
                  />
                  <span>{{ data.value }}</span>
                </label>
              </li>
            </ul>
            <ul class="category" v-else>
              <li
                v-for="(timu, timuIndex) in item.question_list"
                :key="timu.id"
              >
                <div class="question-title">
                  {{ timuIndex + 1 }}、{{ timu.question }}
                </div>
                <label
                  class="question-data inputBox"
                  v-for="(data, index) in timu.answer_count"
                  :key="index"
                >
                  <span>填空{{ index + 1 }}</span
                  ><input
                    class="border"
                    type="text"
                    value=""
                    :name="'timu_'+timu.id "
                    v-model="selectedDta[`question_${item.type}_${timu.id}`][index]"
                  />
                </label>
              </li>
            </ul>
          </li>
        </ul>
        <div class="ope">
          <button class="subBtn" @click="subBtn">提交</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "TextContent",
  data() {
    return {
      content: "",
      selected: "",
      selectedDta: "",
      limitTime: "",
      
    };
  },
  mounted() {
    this.getTextContent(); //获取考试内容
  },
  methods: {
    ...mapMutations(["ShowTipModal", "CloseTipModal"]), //登录弹窗
    //获取考试内容
    getTextContent() {
      let url ='/v1/commonExam/goExam'
      this.$axios
        .post(
          url,
          {
            exam_id: this.$route.params.id,
          },
          { useLog: true }
        )
        .then((res) => {
          if (res.code == 0) {
            let content = res.data;
            let questions = [];

            for(let i in content.questions){
              if(!content.questions[i]){
                content.questions[i] = {}
                content.questions[i].question_list = [];
              }

              questions.push(...content.questions[i].question_list)
            }

            this.content = content;

            let arr = {};

            for (let i = 0; i < questions.length; i++) {
              let key = "question_" + questions[i].type + "_" + questions[i].id;

              if (questions[i].type == 2 || questions[i].type == 4) {
                arr[key] = [];
              } else {
                arr[key] = "";
              }
            }

            this.selectedDta = arr;
            this.limitTime = content.exam_info.limit_time + "分";
            this.countdown(content.exam_info.limit_time);
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
              fun: function () {
                this.$router.go(-1);
              },
            });
          }
        });
    },
    //倒计时
    countdown(date) {
      let _this = this;
      let maxtime = date * 60; //一个小时，按秒计算，自己调整!

      let timer = setInterval(function () {
        if (maxtime >= 0) {
          let minutes = Math.floor(maxtime / 60);
          let seconds = Math.floor(maxtime % 60);
          let msg = minutes + "分" + (seconds != 0 ? seconds + "秒" : "");
          _this.limitTime = msg;
          // if (maxtime == 5 * 60)alert("还剩5分钟");
          --maxtime;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
    //提交
    subBtn() {
      let answers = [];
      let inputList = [];
      let nums=[]
      let selectedDta = this.selectedDta;
      var varList = new Array();
      
      
      Object.keys(selectedDta).forEach(function (key) {
        if (key.split("_")[1] == 4) {
          // 填空题
          answers.push({
            question_id: key.split("_")[2],
            option:
              selectedDta[key] instanceof Array
                ? selectedDta[key].join(",")
                : selectedDta[key],
          });
          
        } else {
          answers.push({
            question_id: key.split("_")[2],
            option:
              selectedDta[key] instanceof Array
                ? selectedDta[key].join("")
                : selectedDta[key],
          });
        }
      });
      let url ='/v1/commonExam/submitExam'
      this.$axios
        .post(
          url,
          {
            exam_id: this.$route.params.id,
            record_id: this.content.record_id,
            answers,
          },
          { useLog: true }
        )
        .then((res) => {
          if (res.code == 0) {
            this.ShowTipModal({
              text: "考试结束，得分" + res.data.score + "分", //提示弹窗内容
              showBtns: true,
              confirmFun: () => {
                this.CloseTipModal();
                this.$router.back();
              },
              cancelFun:function(){
                this.CloseTipModal();
                this.$router.back();
              },
            });
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
              showBtns: true,
              confirmFun: function () {
                this.CloseTipModal();
                this.$router.go(-1);
              },
              cancelFun:function(){
                this.CloseTipModal();
                this.$router.go(-1);
              },
            });
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.inputBox {
  display: flex;
  align-items: center;
  span {
    margin-right: 5px;
  }
  .border {
    border: 1px solid #313052;
    padding: 5px;
  }
}

.text {
  background-color: #f7f7f7;
  padding-bottom: 20px;
  overflow: hidden;
}
.text-content {
  width: 1200px;
  margin: 0 auto;
}
.tip {
  padding: 20px 0;
  font-weight: 900;
}
.left {
  box-sizing: border-box;
  width: 272px;
  padding: 20px;
  background-color: #fff;
  float: left;
  // position: -webkit-sticky;
  // position: sticky;
  // top: 0px;
}
.left-title {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 20px;
    background-color: #1a8cfe;
    margin-right: 10px;
  }
}
.category {
  overflow: hidden;
}
.category-title {
  font-size: 14px;
  color: #28275a;
  font-weight: bold;
  margin: 24px 0 15px;
}
.test-num-box {
  float: left;
  margin: 0 10px 10px 0;
}
.test-num {
  display: block;
  width: 28px;
  height: 20px;
  background: #f2f2f9;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: #b9b6bb;
  &.active {
    background-color: #1a8cfe;
    color: #fff;
  }
}
.right {
  box-sizing: border-box;
  width: 900px;
  background-color: #fff;
  border: 1px solid #dfe0e0;
  border-top: none;
  float: right;
  .title {
    height: 60px;
    border-top: 1px solid #dfe0e0;
    border-bottom: 1px solid #dfe0e0;
    background: #fafafa;
    line-height: 60px;
    font-size: 18px;
    font-weight: bold;
    color: #313052;
    padding-left: 20px;
  }
  .category {
    padding: 30px;
    li {
      border-bottom: 1px solid #dfe0e0;
      margin-bottom: 20px;
      &:last-child {
        margin: 0;
        border: none;
      }
    }
  }
}
.question-data {
  display: block;
  margin-bottom: 22px;
  display: flex;
  color: #352c50;
  font-size: 14px;
  cursor: pointer;
  input {
    margin: 4px 6px 0 0;
  }
  .active {
    color: #409eff;
  }
}
.question-title {
  font-size: 16px;
  margin-bottom: 26px;
}
.ope {
  background-color: #f7f7f7;
  overflow: hidden;
  .subBtn {
    padding: 10px 20px;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    float: right;
    margin: 20px;
    cursor: pointer;
  }
}
</style>